import "./styles.css";
import Keyboard from "./Keyboard/Keyboard";
import { useMemo, useState } from "react";

export default function App() {
    const [cost, setCost] = useState<string>('');
    const [weight, setWeight] = useState<string>('');
    const [memory, setMemory] = useState<string[]>([]);

    const minMemoryResult: string = Math.min(...memory.map((valueStr) => parseFloat(valueStr))).toString();

    const result = useMemo(() => {
        if (cost === '' || weight === '') {
            return '';
        }
        return Math.round(parseFloat(cost) / parseFloat(weight) * 1000).toString();
    }, [cost, weight]);

    const addToMemory = () => {
        if (result === '') {
            return;
        }
        setMemory((memory) => [...memory, result]);
    };

    const onResetMemory = () => {
        setMemory([]);
    };

    return (
        <div className="App">
            <div className="memory" onClick={onResetMemory}>
                {memory.map((result) => (
                    <div className={
                        "result result-value" +
                        (memory.length > 0 && result === minMemoryResult ? ' result_min' : '')
                    }>
                        {result}
                    </div>
                ))}
            </div>
            <div
                onClick={addToMemory}
                // onTouchStart={addToMemory}
                className={"result result_current" + (result === '' ? ' result_empty' : '')}
            >
                <span className='result-value'>{result}</span> руб за кг
            </div>
            <div className="keyboards">
                <Keyboard
                    value={cost}
                    setValue={setCost}
                    units='руб'
                />
                <Keyboard
                    value={weight}
                    setValue={setWeight}
                    units='г'
                />
            </div>
        </div>
    );
}
