import React, { FC, MouseEvent, TouchEvent } from "react";
import KeyboardWrapper from "./KeyboardWrapper/KeyboardWrapper";

interface KeyboardProps {
    value: string
    setValue: (value: string) => void
    units: string
}

const Keyboard: FC<KeyboardProps> = ({ value, setValue, units }) => {

    const onChange = (_value: string) => {
        if (_value === '.' && value.length === 0) {
            return;
        }
        if (_value === '.' && value.includes('.')) {
            return;
        }
        setValue(`${value}${_value}`);
    }

    const onReset = (e: MouseEvent<HTMLInputElement> | TouchEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue('');
    }

    return (
        <div className="keyboard">
            <input
                type='text'
                value={`${value} ${units}`}
                className='input'
                onTouchStart={onReset}
                onMouseDown={onReset}
            />
            <KeyboardWrapper onChange={onChange} />
        </div>
    );
};

export default Keyboard;
